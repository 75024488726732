<template>
  <b-navbar class="text-center navbar" toggleable="lg" type="dark" fixed="top">
    <b-col cols="2">
      <router-link to="/" class="home-icon needsclick" v-show="iconshow"
        ><b-icon
          class="d-lg-none d-md-none d-sm-block d-block"
          icon="house-door-fill"
          font-scale="1.2"
        ></b-icon
      ></router-link>
    </b-col>
    <b-col cols="10" style="padding-left: 0; padding-right: 0">
      <h4 style="margin-bottom: 0; font-weight: 500" class="title">
        {{ title }}
      </h4></b-col
    >
    <!--<b-col cols="2" style="text-align: right">
      <span v-show="iconshow">
        <b-icon
          class="d-lg-none d-md-none d-sm-block d-block needsclick"
          icon="list"
          animation="cylon-vertical"
          font-scale="1.4"
          v-b-toggle.sidebar-no-header
        ></b-icon>
      </span>
    </b-col>
     <b-sidebar id="sidebar-no-header" title="BSTOOLS" shadow  >
      <div class="p-3" style="padding:5px">
        <nav class="mb-3" style="padding:5px">
          <b-nav vertical>
            <b-nav-item  v-for="(item,index) in navdata"  :key="index" :to="item.route" class="nav-line needsclick"> 
              <span class="nav-icon" :style="{ 'background': 'url(' + require('../assets/'+ item.name +'.png') + ') no-repeat center center', 'background-size': '100% 100%'}"></span>
              <span>{{ item.title }} </span>
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar> -->

    <!-- <b-sidebar id="sidebar-no-header" title="BSTOOLS小工具集合" shadow  right>
      <template >
        <div class="p-3">
          <nav class="mb-3">
            <b-nav vertical>
              <b-nav-item  v-for="(item,index) in navdata"  :key="index" :to="item.route"> {{ item.name }} </b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar> -->
  </b-navbar>
</template>

<script>
import common from "../utils/common";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iconshow: {
      type: Boolean,
      default: true,
    },
  },
  name: "Navbar",
  data() {
    return {
      navdata: [],
    };
  },
  mounted() {
    // this.navdata = common.getNavData()
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData;
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  min-height: calc(2em + 1vw);
  .title {
    font-size: calc(1em + 1vw);
  }
}

.home-icon {
  color: #000;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}

.nav-line > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 15px;
}
</style>
